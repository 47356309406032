import React, {useState} from 'react';
import {Button, Form, Modal} from "react-bootstrap";
import axios from "axios";
import Cookies from "js-cookie"


export default function Footer({auth}) {

    const [show, setShow] = useState(false);
    const [inputs, setInputs] = useState([]);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setInputs(values => ({...values, [name]: value}));
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(inputs, 'wyslij')
        const inFifteenMinutes = new Date(new Date().getTime() + 30 * 60 * 1000);

        axios.post('https://davecode.stronawcal.pl/debiutyhalowy/api_auth/save', inputs)
            .then((response) => {
                console.log(response, 'response')
                if (response.data.status === 200) {
                    setShow(false)
                    Cookies.set('auth', 'loginTrue', {expires: inFifteenMinutes})
                    window.location.reload();

                } else {
                    alert('Błędne dane!!!!!')
                }
            });
    }

    const handleLogout = () => {
        Cookies.remove('auth');
        window.location.reload();
    }
    return (
        <>
            <Modal show={show} size="sm" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Logowanie</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <span>Login</span>
                        <Form.Control type="text" name="login" onChange={handleChange}/>

                        <span>Hasło</span>
                        <Form.Control type="password" name="password" onChange={handleChange}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Zamknij
                        </Button>
                        <Button variant="primary" type="submit">
                            Zaloguj
                        </Button>
                    </Modal.Footer>
                </Form>

            </Modal>
            {window.innerWidth > 769 &&

            <div className="login-button">
                {!auth ?
                    <Button
                        variant="dark"
                        size='sm'
                        onClick={handleShow}>
                        Zaloguj
                    </Button> :
                    <Button
                        variant="dark"
                        size='sm'
                        onClick={handleLogout}>
                        Wyloguj
                    </Button>
                }

            </div>
            }
            <div className="footer-container">DaveCode Dawid Czyżewski 2024</div>
        </>
    )
}